/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Polygon,
  Tooltip,
  useMapEvents,
  MapContainerProps,
} from 'react-leaflet';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { LatLngExpression, Map } from 'leaflet';
import { forestRegistryItemType } from '@/utils/types/ForestRegistryType';
import { getPolygonCenter } from '@/utils/helpers/formaters';
import { getForestRegistry } from '../../../forest-registry-services';

interface MapPolygonEditorProps {
  onCoordinatesChange: (
    newCoordinates: { coordinates: { lat: number; lng: number }[] }[],
  ) => void;
  forestRegistry: forestRegistryItemType;
}

const MapPolygonEditor: React.FC<MapPolygonEditorProps> = ({
  onCoordinatesChange,
  forestRegistry,
}) => {
  const [mapState, setMapState] = useState({
    center: [-23.5505, -46.6333] as LatLngExpression,
    zoom: 5,
  });
  const [coordinates, setCoordinates] = useState<LatLngExpression[][]>(
    forestRegistry?.coordinates.map((coord) =>
      coord.coordinates.map((c) => [c.lat, c.lng]),
    ),
  );
  const [otherCoordinates, setOtherCoordinates] = useState<forestRegistryItemType[]>([]);
  const mapRef =
    useRef<
      React.ForwardRefExoticComponent<MapContainerProps & React.RefAttributes<Map>>
    >();

  useEffect(() => {
    if (coordinates.length > 0 && mapRef.current) {
      const bounds = coordinates
        .flat()
        .map((coord) => [coord[0], coord[1]] as LatLngExpression);
      mapRef.current.fitBounds(bounds);
    }
  }, [coordinates, mapRef.current, otherCoordinates]);

  useEffect(() => {
    if (forestRegistry?.id) {
      getOthersCoordinates();
    }
  }, [forestRegistry]);

  async function getOthersCoordinates() {
    try {
      const response = await getForestRegistry({
        fazenda: [forestRegistry.fazenda],
        companyId: forestRegistry.companyId,
        page: 1,
        size: 10000,
      });
      setOtherCoordinates(
        response.forestRegisters.filter((register) => register.id !== forestRegistry.id),
      );
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  }

  const handleCreated = (e: any) => {
    const layer = e.layer;
    const newCoordinates = layer
      .getLatLngs()[0]
      .map((latlng: any) => [latlng.lat, latlng.lng]);
    const updatedCoordinates = [...coordinates, newCoordinates];
    setCoordinates(updatedCoordinates);
    onCoordinatesChange(
      updatedCoordinates.map((polygon) => {
        const isPolygon = polygon[0] === polygon[polygon.length - 1];
        const newPolygon = polygon.map((coord: any) => ({
          lat: coord[0],
          lng: coord[1],
        }));
        return {
          coordinates: isPolygon ? newPolygon : [...newPolygon, newPolygon[0]],
        };
      }),
    );
  };

  const handleEdited = (e: any) => {
    const layers = e.target;
    const updatedCoordinates: LatLngExpression[][] = [];
    layers.eachLayer((layer: any) => {
      if (layer.options.color === 'red') {
        const newCoordinates = layer
          .getLatLngs()[0]
          .map((latlng: any) => [latlng.lat, latlng.lng]);
        updatedCoordinates.push(newCoordinates);
      }
    });
    setCoordinates(updatedCoordinates);
    onCoordinatesChange(
      updatedCoordinates.map((polygon) => {
        const isPolygon = polygon[0] === polygon[polygon.length - 1];
        const newPolygon = polygon.map((coord: any) => ({
          lat: coord[0],
          lng: coord[1],
        }));
        return {
          coordinates: isPolygon ? newPolygon : [...newPolygon, newPolygon[0]],
        };
      }),
    );
  };

  const handleDeleted = (e: any) => {
    const layers = e.target;
    const deletedLayerIds: number[] = [];
    layers.eachLayer((layer: any) => {
      if (layer.options.color === 'red') {
        deletedLayerIds.push(layer._leaflet_id);
      }
    });

    const updatedCoordinates = coordinates.filter((_, index) => {
      return !deletedLayerIds.includes(index);
    });

    setCoordinates(updatedCoordinates);
    onCoordinatesChange(
      updatedCoordinates.map((polygon) => {
        const isPolygon = polygon[0] === polygon[polygon.length - 1];
        const newPolygon = polygon.map((coord: any) => ({
          lat: coord[0],
          lng: coord[1],
        }));
        return {
          coordinates: isPolygon ? newPolygon : [...newPolygon, newPolygon[0]],
        };
      }),
    );
  };

  const updateMapState = () => {
    const map = mapRef.current;
    if (map) {
      const center = map.getCenter();
      const zoom = map.getZoom();
      setMapState({
        center: [center.lat, center.lng],
        zoom: zoom,
      });
    }
  };

  const isPointInBounds = (point) => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      return bounds.contains(point);
    }
  };

  const MIN_ZOOM_TO_SHOW_TOOLTIP = 14;

  const MapEventHandler = ({ onZoomOrMove }: { onZoomOrMove: () => void }) => {
    useMapEvents({
      zoomend: () => {
        onZoomOrMove();
      },
      moveend: () => {
        onZoomOrMove();
      },
    });
    return null;
  };

  return (
    <div className="w-full h-[70vh]">
      <MapContainer
        ref={mapRef}
        center={mapState.center}
        zoom={mapState.zoom}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          url={
            'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
          }
          minZoom={0}
          maxZoom={18}
          tileSize={256}
        />
        {/* <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          tileSize={256}
        /> */}
        <FeatureGroup>
          <MapEventHandler onZoomOrMove={updateMapState} />
          {otherCoordinates.length > 0 &&
            otherCoordinates.map((register, index) => {
              const polygonCenter = getPolygonCenter(register.coordinates);
              if (!polygonCenter) return null;
              const tooltipPosition = polygonCenter
                ? isPointInBounds(polygonCenter)
                  ? [polygonCenter.lat, polygonCenter.lng]
                  : null
                : [-1000, -1000];

              const showTooltip = mapState.zoom >= MIN_ZOOM_TO_SHOW_TOOLTIP;
              return register.coordinates.map((polygon, idx) => (
                <Polygon
                  key={`${index}-${idx}`}
                  positions={polygon.coordinates}
                  color="green"
                >
                  {polygonCenter && showTooltip && (
                    <Tooltip permanent direction="center" position={tooltipPosition}>
                      {register.talhao}
                    </Tooltip>
                  )}
                </Polygon>
              ));
            })}
        </FeatureGroup>
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={handleCreated}
            onEdited={handleEdited}
            onDeleted={handleDeleted}
            draw={{
              rectangle: false,
              circle: false,
              marker: false,
              polyline: false,
              circlemarker: false,
              polygon: {
                allowIntersection: false,
                drawError: {
                  color: '#e1e100',
                  message: '<strong>Ops!</strong> Não pode intersectar!',
                },
                shapeOptions: {
                  color: '#97009c',
                },
              },
            }}
            edit={{
              edit: {
                selectedPathOptions: {
                  maintainColor: true,
                  fillOpacity: 0.0,
                },
              },
              remove: true,
            }}
          />
          {coordinates.length > 0 &&
            coordinates.map((polygon, index) => (
              <Polygon
                key={`map_${index}`}
                positions={polygon}
                fillOpacity={0}
                weight={6}
                color="red"
              />
            ))}
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};

export default MapPolygonEditor;
