import { TitleContext } from '@/component/layout';
import { translationI18n } from '@/i18n';
import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import TableFilters from './components/table-filters';
import CardFiltersStatus from './components/card-filter-status';
import PlantedAreaNoteTable from './components/table';
import { ModalDetailsPlantedAreaNote } from './components/modals/DetailsModal';
import { ModalApprove } from './components/modals/modal-approve';
import { ButtonApprovation } from './components/modals/modal-multi-approve';
import ModalHistory from './components/modals/modal-history';
import { ModalPlantedAreaNoteEdit } from './components/modals/modal-edit';

export const ControlSilviculturePlantedArea = observer(() => {
  const { setTitle, setSubTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle(translationI18n('plantedArea:title'));
    setSubTitle(translationI18n('plantedArea:subtitle'));
  }, []);
  return (
    <div className="w-full min-h-full px-2 py-2 md:px-4 md:py-4 pb-14 md:pb-14 dark:bg-darkblack-700 ">
      <TableFilters />
      <div className="rounded-lg bg-white items-center h-full flex-col dark:bg-darkblack-600 flex-1 shadow-lg">
        <CardFiltersStatus />
        <ButtonApprovation />
        <PlantedAreaNoteTable />
      </div>
      <ModalDetailsPlantedAreaNote />
      <ModalApprove />
      <ModalHistory />
      <ModalPlantedAreaNoteEdit />
    </div>
  );
});
