import SecondaryButton from '@/component/buttons/SecondaryButton';
import Modal from '@/component/modals/modal-default';
import { translationI18n } from '@/i18n';
import { plantedAreaNoteStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const ModalDetailsPlantedAreaNote = observer(() => {
  const { modalStates, handleModal } = plantedAreaNoteStore;
  function handleCloseModal() {
    handleModal('details', false);
  }
  const { note } = modalStates;
  const tCommon = useTranslation('common').t;
  const tPlantedArea = useTranslation('plantedArea').t;
  const Item = ({
    label,
    value,
    suffix = '',
    variant = 'default',
  }: {
    label: string;
    value: any;
    suffix?: string;
    variant?: 'default' | 'highValue' | 'custom' | 'withSuffix';
  }) => {
    return (
      <div>
        <div className="text-xs md:text-sm lg:text-base text-bgray-600 dark:text-bgray-50">
          {label}
        </div>
        {variant === 'withSuffix' ? (
          <div className="flex items-baseline gap-1">
            <div className="text-lg md:text-lg lg:text-2xl dark:text-white">{value}</div>
            <div className="text-xs md:text-sm lg:text-base dark:text-white">
              {suffix}
            </div>
          </div>
        ) : (
          <div className="text-lg md:text-lg lg:text-2xl dark:text-white">{value}</div>
        )}
      </div>
    );
  };
  return (
    <Modal
      isActive={modalStates.details}
      handleClose={handleCloseModal}
      title={translationI18n('plantedArea:listNotes.modals.detailsModal.title', {
        id: modalStates?.note?.cod,
      })}
      footer={
        <div className="flex justify-end gap-3">
          <SecondaryButton text={tCommon('actions.close')} onClick={handleCloseModal} />
        </div>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-y-5">
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.region')}
          value={note?.forestRegistry.regiao}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.farm')}
          value={note?.forestRegistry.fazenda}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.field')}
          value={note?.forestRegistry.talhao}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.fieldArea')}
          suffix={tCommon('ha')}
          variant={'withSuffix'}
          value={`${formatNumber(note?.forestRegistry.area)}`}
        />
        <Item
          suffix={tCommon('ha')}
          variant={'withSuffix'}
          label={tPlantedArea('listNotes.modals.detailsModal.items.noteArea')}
          value={`${formatNumber(note?.area)}`}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.totalArea')}
          suffix={tCommon('ha')}
          variant={'withSuffix'}
          value={`${formatNumber(note?.totalPlantedArea)}`}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.seedlingsUseds')}
          variant={'withSuffix'}
          suffix={tPlantedArea(
            'listNotes.modals.detailsModal.items.seedlingsUsedsSuffix',
          )}
          value={formatNumber(note?.seedlingsUsed, 0)}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.seedlingsDiscarded')}
          variant={'withSuffix'}
          suffix={`${tPlantedArea(
            'listNotes.modals.detailsModal.items.seedlingsUsedsSuffix',
          )} (${formatNumber(note?.percentageDiscarded, 0)}%)`}
          value={formatNumber(note?.seedlingsDiscarded, 0)}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.reasonDiscarded')}
          value={note?.reason.reason}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.density')}
          value={formatNumber(note?.plantingDensity, 0)}
          variant="withSuffix"
          suffix={tPlantedArea('listNotes.modals.detailsModal.items.densitySuffix')}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.geneticMaterial')}
          value={note?.geneticMaterial.genetic}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.user')}
          value={note?.user.name}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.date')}
          value={moment(note?.createdAtMobile).format('DD/MM/YYYY HH:mm:ss')}
        />
        <Item
          label={tPlantedArea('listNotes.modals.detailsModal.items.dateSync')}
          value={moment(note?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
        />
      </div>
    </Modal>
  );
});
