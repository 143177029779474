import Table from '@/component/Table';
import { OptionsType, OrderTableTypes } from '@/component/Table/types';
import { plantedAreaNoteStore, userStore } from '@/store';
import { useFetch } from '@/hooks';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { checkPermission } from '@/utils/helpers/validations';
import EmptyState from '@/component/EmptyState';
import { useTranslation } from 'react-i18next';
import { PlantedAreaNoteInterface } from '../../planted-area-types';
import { columnsState } from '../../planted-area-initial-states';
const PlantedAreaNoteTable = () => {
  const {
    getDataTable,
    filterStatus,
    dataRequest,
    listNotes,
    handleDataRequest,
    loading: loadinStore,
    handleModal,
    filter,
    filtersStatus,
    selectedRows,
    setSelectedRows,
  } = plantedAreaNoteStore;
  const { [filterStatus]: dataRequestStatus } = dataRequest;
  const { [filterStatus]: notes } = listNotes;
  const { loading, request } = useFetch(getDataTable);
  const tPlantedArea = useTranslation('plantedArea').t;
  const tCommon = useTranslation('common').t;
  async function getSurveys() {
    await request();
  }

  useEffect(() => {
    getSurveys();
  }, [dataRequestStatus, filterStatus, filter, userStore.companyActive]);

  const options = (rowItem: PlantedAreaNoteInterface): OptionsType[] => {
    return [
      {
        label: tCommon('actions.approve'),
        variant: 'success',
        action: (row: PlantedAreaNoteInterface) => handleModal('approve', true, row),
        hide: rowItem.nextLevelApproved?.id !== userStore.user.level.id,
      },
      {
        label: tCommon('actions.edit'),
        action: (row: PlantedAreaNoteInterface) => handleModal('edit', true, row),
        hide:
          !checkPermission({
            permission: 'permissionUpdate',
            service: 'plantedArea',
          }) || rowItem.approved,
      },
      {
        label: tPlantedArea('listNotes.table.options.viewHistory'),
        action: (row: PlantedAreaNoteInterface) => handleModal('history', true, row),
      },
      {
        label: tCommon('actions.inactive'),
        variant: 'danger',
        action: (row: PlantedAreaNoteInterface) => handleModal('delete', true, row),
        hide:
          !checkPermission({
            permission: 'permissionDelete',
            service: 'plantedArea',
          }) || !rowItem.active,
      },

      {
        label: tCommon('actions.reactive'),
        variant: 'success',
        action: (row: PlantedAreaNoteInterface) => handleModal('reactive', true, row),
        hide:
          !checkPermission({
            permission: 'permissionDelete',
            service: 'plantedArea',
          }) || rowItem.active,
      },
    ].filter((x) => !x.hide);
  };
  if (
    (!loading && notes.plantingNotes.length === 0) ||
    Number(filtersStatus.filter((option) => option.value === filterStatus)[0]?.value) ===
      0
  ) {
    return (
      <EmptyState
        title={tPlantedArea('listNotes.table.emptyState.title')}
        description={tPlantedArea('listNotes.table.emptyState.description')}
      />
    );
  }
  return (
    <Table
      disableSelectRow={(row: PlantedAreaNoteInterface) => {
        return row.nextLevelApproved?.id !== userStore.user.level.id;
      }}
      selectable={filterStatus === 'flowApproved'}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      columns={columnsState()}
      page={dataRequestStatus.page}
      size={dataRequestStatus.size}
      options={options}
      showSearch={false}
      totalPages={notes.totalPages}
      data={notes.plantingNotes}
      setPage={function (page: number): void {
        handleDataRequest(filterStatus, 'page', page);
      }}
      setSize={function (size: number): void {
        handleDataRequest(filterStatus, 'size', size);
      }}
      clickable={true}
      onPressRow={(row: PlantedAreaNoteInterface) => {
        handleModal('details', true, row);
      }}
      loading={loading || loadinStore.statusFilter || loadinStore.table}
      order={dataRequestStatus.order}
      setOrder={function (order: OrderTableTypes): void {
        handleDataRequest(filterStatus, 'order', order);
      }}
      pt={0}
    />
  );
};
export default observer(PlantedAreaNoteTable);
