import { get, post, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  PlantedAreaGeneticMaterialType,
  PLantedAreaApproveDataInterface,
  PlantedAreaNoteDataRequestGetType,
  PlantedAreaNoteInterface,
  PlantedAreaNoteListInterface,
  PlantedAreaNoteListStatusType,
  PlantedAreaReasonType,
  PlantedAreaNoteDataRequestUpdateType,
} from './planted-area-types';
export async function getListStatus(data: {
  companyId?: number;
}): Promise<PlantedAreaNoteListStatusType> {
  const result = (await get(url.GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE_LIST_STATUS, {
    params: data,
  })) as PlantedAreaNoteListStatusType;
  return result;
}
export async function getPlantedAreaNotes(
  data: PlantedAreaNoteDataRequestGetType,
): Promise<PlantedAreaNoteListInterface> {
  const result = (await get(url.GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE, {
    params: data,
  })) as PlantedAreaNoteListInterface;
  return result;
}
export async function updatePlantedAreaNote(
  data: PlantedAreaNoteDataRequestUpdateType,
): Promise<PlantedAreaNoteInterface> {
  const result = (await put(
    url.GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE + '/' + data.id,
    data,
  )) as PlantedAreaNoteInterface;
  return result;
}
export async function approveNote(
  data: PLantedAreaApproveDataInterface,
): Promise<{ message: string; data: PlantedAreaNoteInterface }> {
  return await post(url.GET_CONTROL_SILVICULTURE_PLANTED_APPROVALS, data);
}
export async function getMyApprovals(data: {
  companyId: number;
}): Promise<PlantedAreaNoteInterface[]> {
  return await get(url.GET_CONTROL_SILVICULTURE_PLANTED_MY_APPROVALS, { params: data });
}
export async function approveNoteArr(data: {
  notes: number[];
}): Promise<PlantedAreaNoteInterface[]> {
  return await post(url.GET_CONTROL_SILVICULTURE_PLANTED_APPROVALS_ARR, data);
}
export async function getPlantedAreaReasons(data: {
  companyId: number;
}): Promise<PlantedAreaReasonType[]> {
  return await get(url.GET_CONTROL_SILVICULTURE_PLANTED_REASONS_MOBILE, { params: data });
}
export async function getPlantedAreaGeneticMaterials(data: {
  companyId: number;
}): Promise<PlantedAreaGeneticMaterialType[]> {
  return await get(url.GET_CONTROL_SILVICULTURE_PLANTED_GENETIC_MATERIALS_MOBILE, {
    params: data,
  });
}
